<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="교육 문제풀이 SET 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn v-if="editable && popupParam.eduQuestionMstId" label="LBLREMOVE" icon="delete_forever" @btnClicked="removeData" /> -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="mstData"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  label="교육 문제풀이 SET명"
                  name="eduQuestionMstName"
                  v-model="mstData.eduQuestionMstName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  required
                  :editable="editable"
                  type="edit"
                  codeGroupCd="EDU_KIND_FST_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="educationKindCdLarge"
                  label="교육종류"
                  v-model="mstData.educationKindCdLarge"
                ></c-select>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  required
                  type="number"
                  :editable="editable"
                  label="통과 기준점수"
                  name="eduQuestionPassScore"
                  v-model="mstData.eduQuestionPassScore">
                </c-text>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="mstData.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="questionGrid"
                  title="문제"
                  tableId="questionGrid"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :filtering="false"
                  :columns="questionGrid.columns"
                  :isExcelDown="false"
                  :data="mstData.questions"
                  gridHeight="600px"
                  selection="multiple"
                  rowKey="eduQuestionId"
                  :editable="editable"
                  @rowClick="getAnswerList"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="LBLADD" icon="add" @btnClicked="addQuestion" v-if="editable && popupParam.eduQuestionMstId" />
                      <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeQuestion" v-if="editable && popupParam.eduQuestionMstId" />
                      <!-- <c-btn label="LBLSAVE" icon="save" @btnClicked="saveQuestion" v-if="editable && popupParam.eduQuestionMstId" /> -->
                    </q-btn-group>
                  </template>
                  <template v-slot:customArea="{ props }">
                    <template>
                      <q-chip
                        v-if="props.row.editFlag !== 'C'"
                        outline square
                        :clickable="false"
                        color="blue"
                        text-color="white"
                        style="margin-top:3px !important;">
                        ▶
                      </q-chip>
                    </template>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="answerGrid"
                  title="답변"
                  tableId="answerGrid"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :filtering="false"
                  :columns="answerGrid.columns"
                  :isExcelDown="false"
                  :data="answers"
                  gridHeight="600px"
                  selection="multiple"
                  rowKey="eduAnswerId"
                  :editable="editable"
                >
                  <template slot="table-chip">
                    <q-btn-group outline >
                      <q-chip v-if="selectedQuestionName" outline square>
                          {{ selectedQuestionName }}
                      </q-chip>
                    </q-btn-group>
                  </template>
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="LBLADD" icon="add" @btnClicked="addAnswer" v-if="editable && popupParam.eduQuestionMstId && selectedQuestionId" />
                      <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeAnswer" v-if="editable && popupParam.eduQuestionMstId && selectedQuestionId" />
                      <!-- <c-btn label="LBLSAVE" icon="save" @btnClicked="saveAnswer" v-if="editable && popupParam.eduQuestionMstId && selectedQuestionId" /> -->
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'question-mst-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduQuestionMstId: '',
        disableEidt: true,
      }),
    },
  },
  data() {
    return {
      saveUrl: transactionConfig.sop.edu.quiz.mst.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      qListUrl: '',
      qSaveUrl: '',
      qDeleteUrl: '',
      aListUrl: '',
      aSaveUrl: '',
      aDeleteUrl: '',
      mstData: {
        eduQuestionMstId: '',  // 교육 문제풀이 마스터 일련번호
        eduQuestionMstName: '',  // 교육 문제풀이 SET명
        educationKindCdLarge: null,  // 교육종류(대)
        eduQuestionPassScore: '',  // 물제풀이 통과 기준점수
        useFlag: 'Y',  // 사용여부
        delFlag: 'N',  // 삭제여부
        questions: [],
      },
      selectedQuestionId: '',
      selectedQuestionName: '',
      answers: [],
      questionGrid: {
        columns: [
          {
            required: true,
            name: 'questionSortOrder',
            field: 'questionSortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:70px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'questionContent',
            field: 'questionContent',
            label: '문제 내용',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'questionPoint',
            field: 'questionPoint',
            label: '배점',
            type: 'number',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'custom',
            field: 'custom',
            label: '답변',
            align: 'center',
            style: 'width:60px',
            type: 'custom',
            sortable: false
          },
        ],
      },
      answerGrid: {
        columns: [
          {
            required: true,
            name: 'answerSortOrder',
            field: 'answerSortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:70px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'answerContent',
            field: 'answerContent',
            label: '답변 내용',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'answerFlag',
            field: 'answerFlag',
            label: '정답여부',
            align: 'center',
            style: 'width:80px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
      },
      updateMode: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable && this.popupParam.disableEidt;
      
      this.detailUrl = selectConfig.sop.edu.quiz.mst.get.url;
      this.insertUrl = transactionConfig.sop.edu.quiz.mst.insert.url;
      this.updateUrl = transactionConfig.sop.edu.quiz.mst.update.url;
      this.deleteUrl = transactionConfig.sop.edu.quiz.mst.delete.url;
      this.qListUrl = selectConfig.sop.edu.quiz.question.list.url;
      this.qSaveUrl = transactionConfig.sop.edu.quiz.question.insert.url;
      this.qDeleteUrl = transactionConfig.sop.edu.quiz.question.delete.url;
      this.aListUrl = selectConfig.sop.edu.quiz.answer.list.url;
      this.aSaveUrl = transactionConfig.sop.edu.quiz.answer.insert.url;
      this.aDeleteUrl = transactionConfig.sop.edu.quiz.answer.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.eduQuestionMstId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.eduQuestionMstId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.mstData = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo',this.mstData)
          // this.getQuestionList();
        },);
      }
    },
    saveData() {
      if (this.popupParam.eduQuestionMstId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.questionGrid.columns, this.mstData.questions)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.mstData.regUserId = this.$store.getters.user.userId;
                this.mstData.chgUserId = this.$store.getters.user.userId;
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.eduQuestionMstId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.eduQuestionMstId = result.data
      this.getDetail();
    },
    getQuestionList() {
      this.selectedQuestionId = '';
      this.selectedQuestionName = '';
      this.answers = [];
      this.$http.url = this.qListUrl;
      this.$http.param = {eduQuestionMstId: this.popupParam.eduQuestionMstId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstData.questions = _result.data;
      },);
    },
    addQuestion() {
      this.selectedQuestionId = '';
      this.selectedQuestionName = '';
      this.answers = [];
      this.mstData.questions.push({
        editFlag: 'C',
        eduQuestionMstId: this.popupParam.eduQuestionMstId,  // 교육 문제풀이 마스터 일련번호
        eduQuestionId: uid(),  // 교육 문제풀이 문제 일련번호
        questionSortOrder: this.mstData.questions.length + 1,  // 문제 순번
        questionContent: '',  // 문제내용
        questionPoint: '',  // 문제 배점
        delFlag: 'N',  // 삭제여부
      })
    },
    removeQuestion() {
      let selectData = this.$refs['questionGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.qDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.mstData.questions = this.$_.reject(this.mstData.questions, item);
              })
              this.$refs['questionGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveQuestion() {
      if (this.$comm.validTable(this.questionGrid.columns, this.mstData.questions)) {
        let totalPoint = 0;
        this.$_.forEach(this.mstData.questions, item => {
          totalPoint += parseInt(item.questionPoint);
        });
        let saveData = this.mstData.questions.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if (totalPoint > 100 || totalPoint < 100 ) {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '배점의 합계가 100점이 되도록 입력하시기 바랍니다.', /* 저장되었습니다. */
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.qSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
                this.getDetail();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
    getAnswerList(row) {
      this.answers = row;
      this.selectedQuestionId = row.eduQuestionId;
      this.selectedQuestionName = row.questionContent;
      // this.$http.url = this.aListUrl;
      // this.$http.param = {eduQuestionId: row.eduQuestionId};
      // this.$http.type = 'GET';
      // this.$http.request((_result) => {
      //   this.answers = _result.data;
      // },);
    },
    addAnswer() {
      this.answers.push({
        editFlag: 'C',
        eduQuestionId: this.selectedQuestionId,  // 교육 문제풀이 문제 일련번호
        eduAnswerId: uid(),  // 교육 문제풀이 답변 일련번호
        answerSortOrder: this.answers.length + 1,  // 정답 순번
        answerContent: '',  // 정답내용
        answerFlag: 'N',  // 정답여부
        delFlag: 'N',  // 삭제여부
      })
    },
    removeAnswer() {
      let selectData = this.$refs['answerGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.aDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.answers = this.$_.reject(this.answers, item);
              })
              this.$refs['answerGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveAnswer() {
      if (this.$comm.validTable(this.answerGrid.columns, this.answers)) {
        let answerFlagCnt = 0;
        this.$_.forEach(this.answers, item => {
          if (item.answerFlag == 'Y') {
            answerFlagCnt++;
          }
        });
        let saveData = this.answers.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });

        if (answerFlagCnt != 1) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '정답인 답변이 하나 선택되어야 합니다.', // 선택된 항목이 없습니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.aSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
                this.getAnswerList({eduQuestionId: this.selectedQuestionId, questionContent: this.selectedQuestionName});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
  }
};
</script>
