var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "교육 문제풀이 SET 정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.mstData,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "교육 문제풀이 SET명",
                            name: "eduQuestionMstName",
                          },
                          model: {
                            value: _vm.mstData.eduQuestionMstName,
                            callback: function ($$v) {
                              _vm.$set(_vm.mstData, "eduQuestionMstName", $$v)
                            },
                            expression: "mstData.eduQuestionMstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            type: "edit",
                            codeGroupCd: "EDU_KIND_FST_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "educationKindCdLarge",
                            label: "교육종류",
                          },
                          model: {
                            value: _vm.mstData.educationKindCdLarge,
                            callback: function ($$v) {
                              _vm.$set(_vm.mstData, "educationKindCdLarge", $$v)
                            },
                            expression: "mstData.educationKindCdLarge",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.mstData.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.mstData, "useFlag", $$v)
                            },
                            expression: "mstData.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "questionGrid",
                            attrs: {
                              title: "문제",
                              tableId: "questionGrid",
                              columnSetting: false,
                              isFullScreen: false,
                              usePaging: false,
                              filtering: false,
                              columns: _vm.questionGrid.columns,
                              isExcelDown: false,
                              data: _vm.mstData.questions,
                              gridHeight: "600px",
                              selection: "multiple",
                              rowKey: "eduQuestionId",
                              editable: _vm.editable,
                            },
                            on: { rowClick: _vm.getAnswerList },
                            scopedSlots: _vm._u([
                              {
                                key: "customArea",
                                fn: function ({ props }) {
                                  return [
                                    [
                                      props.row.editFlag !== "C"
                                        ? _c(
                                            "q-chip",
                                            {
                                              staticStyle: {
                                                "margin-top": "3px !important",
                                              },
                                              attrs: {
                                                outline: "",
                                                square: "",
                                                clickable: false,
                                                color: "blue",
                                                "text-color": "white",
                                              },
                                            },
                                            [_vm._v(" ▶ ")]
                                          )
                                        : _vm._e(),
                                    ],
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable &&
                                    _vm.popupParam.eduQuestionMstId
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "LBLADD",
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addQuestion },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.popupParam.eduQuestionMstId
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "LBLREMOVE",
                                            icon: "remove",
                                          },
                                          on: {
                                            btnClicked: _vm.removeQuestion,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "answerGrid",
                            attrs: {
                              title: "답변",
                              tableId: "answerGrid",
                              columnSetting: false,
                              isFullScreen: false,
                              usePaging: false,
                              filtering: false,
                              columns: _vm.answerGrid.columns,
                              isExcelDown: false,
                              data: _vm.answers,
                              gridHeight: "600px",
                              selection: "multiple",
                              rowKey: "eduAnswerId",
                              editable: _vm.editable,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-chip" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.selectedQuestionName
                                      ? _c(
                                          "q-chip",
                                          {
                                            attrs: { outline: "", square: "" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedQuestionName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable &&
                                    _vm.popupParam.eduQuestionMstId &&
                                    _vm.selectedQuestionId
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "LBLADD",
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addAnswer },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.popupParam.eduQuestionMstId &&
                                    _vm.selectedQuestionId
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "LBLREMOVE",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeAnswer },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }